import { Hero } from './components/Hero'

function App() {
  return (
    <main>
      <Hero />
    </main>
  )
}

export default App
