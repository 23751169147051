import { GoArrowUpRight } from 'react-icons/go'
import './index.scss'

interface Props {
  prefix?: React.ReactNode
  href: string
  children: React.ReactNode
}

export function ExternalLink({ prefix, children, ...props }: Props) {
  return (
    <a {...props} target='_blank' rel='noopener noreferrer' className='external-link'>
      {prefix && <span className='prefix'>{prefix}</span>}
      {children}
      <GoArrowUpRight />
    </a>
  )
}
