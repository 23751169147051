import { ExternalLink } from '../ExternalLink'
import './index.scss'
import BulkDownloadingVideo from '../../assets/media/bulk-downloading.mp4'
import { VscGithub } from 'react-icons/vsc'
import { SiNpm } from 'react-icons/si'
import { useEffect, useState } from 'react'

export function Hero() {
  const [version, setVersion] = useState(null)

  useEffect(() => {
    const fetchVersion = async () => {
      const response = await fetch('https://registry.npmjs.org/libgen-downloader')
      const data = await response.json()
      setVersion(data['dist-tags'].latest)
    }

    fetchVersion()
  }, [])

  return (
    <div className='hero'>
      <div className='video-wrapper'>
        <video src={BulkDownloadingVideo} autoPlay loop muted />
      </div>

      <div>
        <div className='main-title'>
          <h1>Libgen Downloader</h1>

          <a
            href='https://github.com/obsfx/libgen-downloader'
            target='_blank'
            rel='noopener noreferrer'
            className='version-label'
          >
            <VscGithub />
            <span>GitHub</span>
          </a>

          <a
            href='https://www.npmjs.com/package/libgen-downloader'
            target='_blank'
            rel='noopener noreferrer'
            className='version-label'
          >
            <SiNpm />
            <span>{version ? `v${version}` : '...'}</span>
          </a>
        </div>

        <p>
          A command-line tool to search and download books from libgen. You can download books from
          multiple mirrors. It also supports bulk downloading. It has a responsive resizable UI that
          renders in your terminal.
        </p>

        <p>
          Visit{' '}
          <ExternalLink href='https://github.com/obsfx/libgen-downloader'>GitHub</ExternalLink> for
          detailed information.
        </p>
      </div>

      <div className='section'>
        <h2>Installation</h2>

        <p>
          If you have <ExternalLink href='https://nodejs.org/en/'>Node.js</ExternalLink> and{' '}
          <ExternalLink href='https://www.npmjs.com/package/libgen-downloader'>npm</ExternalLink>{' '}
          installed, you can use{' '}
          <ExternalLink href='https://www.npmjs.com/package/libgen-downloader'>npm</ExternalLink> to
          install libgen-downloader.
        </p>

        <pre>
          <code>npm install -g libgen-downloader</code>
        </pre>

        <p>
          If you don't want to install all Node.js and npm, you can download one of the standalone
          binaries. Visit{' '}
          <ExternalLink href='https://github.com/obsfx/libgen-downloader/releases'>
            GitHub Releases
          </ExternalLink>{' '}
          to download the latest version.
        </p>
      </div>
    </div>
  )
}
